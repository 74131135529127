document.addEventListener('turbo:load', () => {
  const form = document.querySelector('form');
  if (form) {
    form.addEventListener('submit', (event) => {
      const emailInput = form.querySelector('input[type="email"]');
      const email = emailInput.value;
      const regex = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i;

      if (!regex.test(email)) {
        event.preventDefault();
        alert('Please enter a valid email address (e.g., user@example.com).');
        emailInput.focus();
      }
    });
  }
});