// Core Libraries
import '@hotwired/turbo-rails';
import 'channels';
import 'controllers';

import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import 'trix';
import '@rails/actiontext';
import Sortable from 'sortablejs';

Rails.start();
ActiveStorage.start();

// jQuery and Plugins
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'chosen-js/chosen.css';
import 'chosen-js';

import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap'; // Ensure Bootstrap JS is properly imported

// Toastr for Notifications
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import './stylesheets/custom_toastr.scss';
window.toastr = toastr;

// Toastr Configuration
toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  timeOut: '5000',
  extendedTimeOut: '1000',
  newestOnTop: true,
  preventDuplicates: true,
};

// Other Libraries
import Chart from 'chart.js/auto';
import 'dropzone';

// Custom Scripts
import './bootstrap_custom.js';
import './stylesheets/home.scss';
import './stylesheets/application.scss';
import './email_validation';

// Function to Display Flash Messages
function displayFlashMessages() {
  console.log('inside displayFlashMessages');

  const flashDataDiv = document.getElementById('flash-data');

  if (!flashDataDiv) {
    console.log('No flash data div found');
    return; // No flash messages to display
  }

  if (flashDataDiv) {
    try {
      const flashData = JSON.parse(flashDataDiv.dataset.flash);
      console.log('Flash data:', flashData);

      Object.entries(flashData).forEach(([key, message]) => {
        const type = getToastrType(key);
        const title = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize the type
        toastr[type](message, title);
      });
    } catch (error) {
      console.error('Error parsing flash data:', error);
    }
  }
}

// Function to Display Devise Error Messages
function displayDeviseErrorMessages() {
  const errorMessagesDiv = document.getElementById('devise-error-messages');
  if (errorMessagesDiv) {
    try {
      const errors = JSON.parse(errorMessagesDiv.dataset.errors);
      errors.forEach((message) => {
        toastr.error(message, 'error');
      });
    } catch (error) {
      console.error('Error parsing Devise error messages:', error);
    }
  }
}

// Function to Determine Toastr Type Based on Flash Key
function getToastrType(key) {
  switch (key) {
    case 'notice':
      return 'success';
    case 'alert':
      return 'error';
    case 'warning':
      return 'warning';
    default:
      return 'info';
  }
}

// Function to Initialize Charts
function initializeCharts() {
  const ctx1 = document.getElementById('page-views');
  if (ctx1) {
    new Chart(ctx1, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx1.dataset.labels),
        datasets: [
          {
            label: 'Page Views',
            data: JSON.parse(ctx1.dataset.data),
            borderWidth: 1,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }

  const ctx2 = document.getElementById('unique-page-views');
  if (ctx2) {
    new Chart(ctx2, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx2.dataset.labels),
        datasets: [
          {
            label: 'Unique Page Views',
            data: JSON.parse(ctx2.dataset.data),
            borderWidth: 1,
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgba(153, 102, 255, 1)',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  }
}

// Function to Initialize Stripe
function initializeStripe() {
  let cardElement = document.querySelector('#card-element');

  if (cardElement !== null) {
    setupStripe();
  }

  let newCard = document.querySelector('#use-new-card');
  if (newCard !== null) {
    newCard.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('#payment-form').classList.remove('d-none');
      document.querySelector('#existing-card').classList.add('d-none');
    });
  }
}

function setupStripe() {
  const stripe_key = document
    .querySelector("meta[name='stripe-key']")
    .getAttribute('content');
  const stripe = Stripe(stripe_key);

  const elements = stripe.elements();
  const card = elements.create('card');
  card.mount('#card-element');

  const displayError = document.getElementById('card-errors');

  card.addEventListener('change', (event) => {
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  const form = document.querySelector('#payment-form');
  let paymentIntentId = form.dataset.paymentIntent;
  let setupIntentId = form.dataset.setupIntent;

  console.log('Payment Intent ID:', paymentIntentId);

  form.addEventListener('submit', (event) => {
    event.preventDefault();

    let name = form.querySelector('#name_on_card').value; // Corrected to .value
    let data = {
      payment_method_data: {
        card: card,
        billing_details: { name: name },
      },
    };

    // Handle payment intents and setup intents
    if (paymentIntentId) {
      if (form.dataset.status === 'requires_action') {
        stripe
          .confirmCardPayment(paymentIntentId, {
            payment_method: data.payment_method_data,
            setup_future_usage: 'off_session',
          })
          .then((result) => {
            if (result.error) {
              displayError.textContent = result.error.message;
              form.querySelector('#card-details').classList.remove('d-none');
            } else {
              form.submit();
            }
          });
      }
    } else if (setupIntentId) {
      // Updating a card or subscribing with a trial (using a SetupIntent)
      stripe
        .confirmCardSetup(setupIntentId, {
          payment_method: data.payment_method_data,
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
          } else {
            addHiddenField(
              form,
              'payment_method_id',
              result.setupIntent.payment_method
            );
            form.submit();
          }
        });
    } else {
      // Subscribing with no trial
      data.payment_method_data.type = 'card';
      stripe.createPaymentMethod(data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message;
        } else {
          addHiddenField(form, 'payment_method_id', result.paymentMethod.id);
          form.submit();
        }
      });
    }
  });
}

function addHiddenField(form, name, value) {
  let hiddenInput = document.createElement('input');
  hiddenInput.type = 'hidden';
  hiddenInput.name = name;
  hiddenInput.value = value;
  form.appendChild(hiddenInput);
}

// Initialize Everything on Turbo Load
document.addEventListener('turbo:load', () => {
  console.log('Turbo loaded');

  // Initialize Chosen.js dropdown
  if ($('.chosen-select').length > 0) {
    console.log('Initializing Chosen.js');
    $('.chosen-select').chosen({
      allow_single_deselect: true,
      no_results_text: 'No results matched',
      width: '100%',
    });
    console.log('Chosen.js initialized');
  }

  // Initialize Charts
  initializeCharts();

  // Display flash messages using Toastr
  displayFlashMessages();

  // Display Devise error messages using Toastr
  displayDeviseErrorMessages();

  // Initialize Stripe if needed
  initializeStripe();
});
